import React, { useState, useEffect } from "react";
import {
  Create,
  FormTab,
  TabbedForm,
  TextInput,
  required,
  NumberInput,
  Button,
  SelectInput,
  useGetIdentity,
  useNotify,
  useRedirect,
  Toolbar,
  SaveButton,
  Confirm,
} from "react-admin";
import { Grid, InputAdornment, TextField } from "@mui/material";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import SearchSAM from "../components/SearchSAM";
import { useFormContext, useWatch } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { apiUrl } from "../ApiUrl";
import AddIcon from "@mui/icons-material/Add";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const BookingCreate = (props) => {
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const [createSearchValue, setCreateSearchValue] = React.useState("");
  const [textValue, setTextValue] = React.useState("");
  const { identity } = useGetIdentity();
  const [collectShipper, setCollectShipper] = React.useState(false);
  const [searchIsView, setSearchIsView] = React.useState(true);
  const [consigneeValue, setConsigneeValue] = React.useState("");
  const [isDoc, setIsDoc] = React.useState(false);
  const [productCodeValue, setProductCodeValue] = React.useState("Document");
  const [packingTypeValue, setPackingTypeValue] = React.useState("");
  const [optionGroup, setOptionGroup] = React.useState("1");
  const [bookingId, setBookingId] = React.useState("");

  const [pickupdateValue, setPickupdateValue] = React.useState(
    dayjs().format("MM/DD/YYYY")
  );

  const [pickupdateValue2, setPickupdateValue2] = React.useState("");

  const redirect = useRedirect();
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => {
    redirect("/bookings");
    setOpen(false);
  };
  const handleConfirm = () => {
    if (optionGroup == 1) {
      window.location.reload();
    }
    if (optionGroup == 2 || optionGroup == 3) {
      const response = axios
        .get(apiUrl + "/shipmentlabel?id=" + bookingId, {
          responseType: "blob",
        })
        .then((res) => {
          var blob = new Blob([res.data], { type: "application/pdf" }); //this make the magic
          var blobURL = URL.createObjectURL(blob);

          var iframe = document.createElement("iframe"); //load content in an iframe to print later
          iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.src = blobURL;
          document.body.appendChild(iframe);

          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        });
      // window.location.reload();
      if (optionGroup == 3) {
        redirect("/bookings");
      }
    }

    setOpen(false);
  };

  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
  const dateParseRegex = /(\d{4})-(\d{2})-(\d{2})/;

  const convertDateToString = (value) => {
    // value is a `Date` object
    if (!(value instanceof Date) || isNaN(value.getDate())) return "";
    const pad = "00";
    const yyyy = value.getFullYear().toString();
    const MM = (value.getMonth() + 1).toString();
    const dd = value.getDate().toString();
    return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
    // // value is a `Date` object
    // if (!(value instanceof Date) || isNaN(value.getDate())) return '';
    // const pad = '00';
    // const yyyy = value.getFullYear().toString();
    // const MM = (value.getMonth() + 1).toString();
    // const dd = value.getDate().toString();
    // return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
  };

  const dateFormatter = (value) => {
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (value == null || value === "") return "";
    if (value instanceof Date) return convertDateToString(value);
    // Valid dates should not be converted
    if (dateFormatRegex.test(value)) return value;

    return convertDateToString(new Date(value));
    // // null, undefined and empty string values should not go through dateFormatter
    // // otherwise, it returns undefined and will make the input an uncontrolled one.
    // if (value == null || value === '') return '';
    // if (value instanceof Date) return convertDateToString(value);
    // // Valid dates should not be converted
    // if (dateFormatRegex.test(value)) return value;

    // return convertDateToString(new Date(value));
  };

  const dateParser = (value) => {
    //value is a string of "YYYY-MM-DD" format
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    var now = new Date(),
      minDate = now.toISOString().substring(0, 10);
    if (value < minDate) {
      notify("The Pickup Date must be Bigger or Equal to today date");
      return getDefaultDate();
    }

    const match = dateParseRegex.exec(value);

    if (match === null) return;

    const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);

    if (isNaN(d.getDate())) return;

    return d;
  };

  const [userInfo, setUserInfo] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("auth");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [shipmentDto, setshipmentDto] = React.useState({
    accountNo: userInfo.accountNo,
    accountName: userInfo.accountName,
    accountAddress: userInfo.accountAddress,
    shipperName: userInfo.department,
    originPort: userInfo.originPort,
  });
  const [address, setAddress] = React.useState({
    id: "",
    region: "",
    province: "",
    municipality: "",
    barangay: "",
    portcode: "",
    areacode: "",
    zipcode: "",
    portcode: "",
    street: "",
  });
  const [consignees, setConsignees] = React.useState([]);

  const [affiliates, setAffiliates] = React.useState([]);
  const [isAffiliate, setIsAffiliate] = React.useState(true);

  const address_temp = React.useState({
    id: "",
    region: "",
    province: "",
    municipality: "",
    barangay: "",
    portcode: "",
    areacode: "",
    zipcode: "",
    portcode: "",
    street: "",
  });

  const passData = (data) => {
    setAddress(data);
    // props.test ="sss";
  };
  useEffect(() => {}, [address]);
  useEffect(() => {}, [setConsigneeValue]);

  useEffect(() => {
    if (pickupdateValue2 === "") {
      setPickupdateValue2(getDefaultDate());
    }
  }, [pickupdateValue]);

  useEffect(() => {
    if (userInfo.accountParentId !== 0) {
      axios
        .get(
          apiUrl +
            "/CollectShipper?accountParentId=" +
            userInfo.accountParentId +
            "&clientId=" +
            userInfo.clientId,
          {}
        )
        .then((res) => {
          console.log(res.data);
          setConsignees(res.data);
          console.log(consignees);
        });

      setCollectShipper(true);
    }
  }, [userInfo]);

  const handleFindClick = (event) => {
    setTextValue(createSearchValue);
    setDialogIsOpen(true);
  };

  const openDialog = () => setDialogIsOpen(true);
  const closeDialog = () => setDialogIsOpen(false);

  const req = [required()];
  const [consigneeMobileNoValue, setConsigneeMobileNo] = React.useState("");

  const transform = (data) => ({
    ...data,
    consigneeRegion: address.region,
    consigneeProvince: address.province,
    consigneeMunicipality: address.municipality,
    consigneeBarangay: address.barangay,
    consigneeZipCode: address.zipcode,
    consigneeAreaCode: address.areacode,
    consigneeStreet: address.street,
    destinationPort: address.portcode,
    shipperAccountNo: identity.accountNo,
    accountName: identity.accountName,
    shipperAddress: identity.accountAddress,
    shipperName: identity.shipperName,
    originport: identity.originPort,
    userName: identity.userName,
    clientId: identity.clientId,
    consigneeName: consigneeValue,
    productCode: productCodeValue,
    packingType: packingTypeValue,
    pickupDate_String: pickupdateValue2,
    consigneeMobileNo: consigneeMobileNoValue,
    isIndividual:
      userInfo.clientId == 1 || (userInfo.clientId != 1 && searchIsView),
  });

  function textChange(name, value) {
    address_temp.region = address.region;
    address_temp.province = address.province;
    address_temp.municipality = address.municipality;
    address_temp.barangay = address.barangay;
    address_temp.portcode = address.portcode;
    address_temp.areacode = address.areacode;
    address_temp.zipcode = address.zipcode;
    address_temp.street = address.street;
    address_temp.street2 = address.street2;

    if (name == "region") address_temp.region = value;
    if (name == "province") address_temp.province = value;
    if (name == "municipality") address_temp.municipality = value;
    if (name == "barangay") address_temp.barangay = value;
    if (name == "portcode") address_temp.portcode = value;
    if (name == "areacode") address_temp.areacode = value;
    if (name == "zipcode") address_temp.zipcode = value;
    if (name == "street") address_temp.street = value;
    if (name == "street2") address_temp.street = value;

    setAddress(address_temp);
  }

  const productCode = ["Document", "Non-Document", "Cargo"];
  const packingType = {
    Document: ["QPKL - Large", "QPKM - Medium", "QPKS - Small"],
    NonDocument: ["QNDL - Large", "QNDM - Medium", "QNDS - Small"],
    Cargo: [
      "Box",
      "LQB - Large Box",
      "MQB - Medium Box",
      "SQB - Small Box",
      "PCS - Piece(s)",
    ],
  };

  const toChoices = (items) => items.map((item) => ({ id: item, name: item }));

  const PackageInput = (props) => {
    const productCode = useWatch({ name: "productCode" });

    return (
      <SelectInput
        choices={productCode ? toChoices(packingType[productCode]) : []}
        {...props}
      />
    );
  };

  function getDefaultDate() {
    var today = new Date();
    var nextFriday = new Date();
    nextFriday = today;
    if (userInfo.clientId === 6) {
      nextFriday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay() + 5
      );
    }

    setPickupdateValue(dayjs(nextFriday).format("MM/DD/YYYY"));
    return nextFriday;
  }

  const RadioButtonsGroup = (props) => {
    return (
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="1"
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="1"
            control={
              <Radio
                onClick={() => {
                  setOptionGroup(1);
                }}
              />
            }
            label="Create another booking."
          />
          <FormControlLabel
            value="2"
            control={
              <Radio
                onClick={() => {
                  setOptionGroup(2);
                }}
              />
            }
            label="Print and Create another booking."
          />
          <FormControlLabel
            value="3"
            control={
              <Radio
                onClick={() => {
                  setOptionGroup(3);
                }}
              />
            }
            label="Print and Redirect to bookings."
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const notify = useNotify();

  const MyToolbar = () => {
    const { reset } = useFormContext();

    const redirect = useRedirect();
    return (
      <Toolbar>
        <SaveButton
          label="Save"
          mutationOptions={{
            onSuccess: (data) => {
              address_temp.region = "";
              address_temp.province = "";
              address_temp.portcode = "";
              address_temp.areacode = "";
              address_temp.zipcode = "";
              address_temp.barangay = "";
              address_temp.municipality = "";
              address_temp.street = "";
              address_temp.street2 = "";
              setSearchIsView(true);
              setConsigneeValue("");
              setAddress(address_temp);

              setOptionGroup("1");
              setBookingId(data.id);
              notify("Successfully saved.");
              reset();
              handleClick();
            },
            onError: ({ status, message, body, json }) => {
              notify(`${body}`);
            },
          }}
          type="button"
        />
      </Toolbar>
    );
  };
  const handleChangePickupDate = (value) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const pickupTemp = new Date(dayjs(value).format("YYYY-MM-DD"));
    pickupTemp.setHours(0, 0, 0, 0);

    if (
      pickupTemp.toISOString().substring(0, 10) <
      today.toISOString().substring(0, 10)
    ) {
      notify("The Pickup Date must be Bigger or Equal to today date");
      return today;
    }

    setPickupdateValue(dayjs(value).format("MM/DD/YYYY"));
    setPickupdateValue2(dayjs(value).format("MM/DD/YYYY"));
  };

  return (
    <div>
      <SearchSAM
        open={dialogIsOpen}
        onClose={closeDialog}
        searchValue={textValue}
        passData={passData}
      />
      <div>
        <Create {...props} transform={transform}>
          <TabbedForm defaultValues={{ sales: 0 }} toolbar={<MyToolbar />}>
            <FormTab label="Consignee" sx={{ maxWidth: "60em" }}>
              <Grid container columnSpacing={1}>
                <Grid item xs={12} sm={3}>
                  <TextInput source="code" label="2GO Tracking" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div hidden={collectShipper}>
                    <TextField
                      label="Name"
                      autoComplete="on"
                      defaultValue={consigneeValue}
                      onChange={(e) => {
                        setConsigneeValue(e.target.value);
                      }}
                      fullWidth
                    />
                  </div>
                  <div hidden={!collectShipper}>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={consignees.map((option) => option.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Consignee Name"
                          onChange={(e) => {
                            setConsigneeValue(e.target.value);
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue === "INTERNATIONAL TOYWORLD INC") {
                          setIsAffiliate(false);
                          setSearchIsView(false);
                          axios
                              .get(
                                apiUrl +
                                  "/CollectShipper?accountParentId=93" +
                                  "&clientId="+ userInfo.clientId,
                                {}
                              )
                              .then((res) => {
                                console.log(res.data);
                                setAffiliates(res.data);
                                console.log(consignees);
                              });
                        } else {
                          setIsAffiliate(true);
                          address_temp.region = "";
                          address_temp.province = "";
                          address_temp.portcode = "";
                          address_temp.areacode = "";
                          address_temp.zipcode = "";
                          address_temp.barangay = "";
                          address_temp.municipality = "";
                          address_temp.street = "";
                          address_temp.street2 = "";
                          setSearchIsView(true);
                          setConsigneeValue(newValue);
                          if (newValue !== null) {
                            const result = consignees.find(
                              ({ name }) => name === newValue
                            );

                            address_temp.id = result.id;
                            address_temp.region = result.region;
                            address_temp.province = result.province;
                            address_temp.municipality = result.municipality;
                            address_temp.barangay = result.barangay;
                            address_temp.portcode = result.destinationPort;
                            address_temp.areacode = result.areaCode;
                            address_temp.zipcode = result.zipCode;
                            if (userInfo.clientId > 6)
                              address_temp.street = result.address;
                              setSearchIsView(false);
                          }
                          setAddress(address_temp);
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Mobile No."
                    autoComplete="on"
                    placeholder="09153602800"
                    type="number"
                    defaultValue={consigneeMobileNoValue}
                    inputProps={{
                      min: "0",
                      max: "9",
                      maxLength: 11,
                    }}
                    onInput={(e) => {
                      e.target.value =
                        "0" +
                        Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                    }}
                    required={
                      userInfo.clientId === 1 ||
                      (userInfo.clientId !== 1 && searchIsView)
                    }
                    onChange={(e) => {
                      setConsigneeMobileNo(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} hidden={isAffiliate}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={affiliates.map((option) => option.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company/Location"
                        onChange={(e) => {
                          setConsigneeValue(e.target.value );
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                        address_temp.region = "";
                        address_temp.province = "";
                        address_temp.portcode = "";
                        address_temp.areacode = "";
                        address_temp.zipcode = "";
                        address_temp.barangay = "";
                        address_temp.municipality = "";
                        address_temp.street = "";
                        address_temp.street2 = "";
                        // setSearchIsView(true);
                        setConsigneeValue(newValue);
                        if (newValue !== null) {
                          const result = affiliates.find(
                            ({ name }) => name === newValue
                          );

                          address_temp.id = result.id;
                          address_temp.region = result.region;
                          address_temp.province = result.province;
                          address_temp.municipality = result.municipality;
                          address_temp.barangay = result.barangay;
                          address_temp.portcode = result.destinationPort;
                          address_temp.areacode = result.areaCode;
                          address_temp.zipcode = result.zipCode;
                          if (userInfo.clientId > 6)
                            address_temp.street = result.address;
                            setSearchIsView(false);
                        }
                        setAddress(address_temp);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} hidden={searchIsView}>
                  <TextInput
                    source="contactPerson"
                    label="Contact Person"
                    fullWidth
                    defaultValue={""}
                  />
                </Grid>

                <Grid item xs={12} sm={2} hidden={!searchIsView}>
                  <h3>Address</h3>
                </Grid>
                <Grid item xs={12} sm={6} hidden={!searchIsView}>
                  <TextField
                    placeholder="Search "
                    fullWidth
                    onChange={(e) => {
                      setCreateSearchValue(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} hidden={!searchIsView}>
                  <Button
                    label="Find"
                    onClick={() => {
                      handleFindClick();
                    }}
                  >
                    <LocationSearchingIcon />
                  </Button>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <p></p>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    validate={req}
                    label="Region"
                    id="region"
                    variant="outlined"
                    value={address.region}
                    disabled={userInfo.clientId != 11 && userInfo.clientId != 1}
                    fullWidth
                    onChange={(e) => {
                      textChange(e.target.id, e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    validate={req}
                    label="Province"
                    id="province"
                    variant="outlined"
                    value={address.province}
                    disabled={userInfo.clientId != 11 && userInfo.clientId != 1}
                    fullWidth
                    onChange={(e) => {
                      textChange(e.target.id, e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    validate={req}
                    id="municipality"
                    label="Municipality"
                    variant="outlined"
                    value={address.municipality}
                    disabled={userInfo.clientId != 11 && userInfo.clientId != 1}
                    fullWidth
                    onChange={(e) => {
                      textChange(e.target.id, e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <p></p>
                </Grid>
                <Grid item xs={12} sm={6} hidden={searchIsView}>
                  <TextField
                    validate={req}
                    id="street"
                    label="Street"
                    variant="standard"
                    fullWidth
                    value={address.street}
                    onChange={(e) => {
                      textChange(e.target.id, e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} hidden={!searchIsView}>
                  <TextField
                    label="Street"
                    id="street2"
                    variant="standard"
                    fullWidth
                    value={address.street2}
                    placeholder="House #, street, building & subdivision name"
                    required={
                      userInfo.clientId == 1 ||
                      (userInfo.clientId != 1 && searchIsView)
                    }
                    onChange={(e) => {
                      textChange(e.target.id, e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="barangay"
                    label="Barangay"
                    variant="outlined"
                    disabled={userInfo.clientId != 11 && userInfo.clientId != 1}
                    value={address.barangay}
                    fullWidth
                    onChange={(e) => {
                      textChange(e.target.id, e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    validate={req}
                    id="areacode"
                    label="Area Code"
                    variant="outlined"
                    value={address.areacode}
                    disabled={userInfo.clientId != 11 && userInfo.clientId != 1}
                    fullWidth
                    onChange={(e) => {
                      textChange(e.target.id, e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="zipcode"
                    label="Zip Code"
                    variant="outlined"
                    fullWidth
                    value={address.zipcode}
                    disabled={userInfo.clientId != 11 && userInfo.clientId != 1}
                    onChange={(e) => {
                      textChange(e.target.id, e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    validate={req}
                    id="portcode"
                    label="Destination Port"
                    variant="outlined"
                    fullWidth
                    value={address.portcode}
                    disabled={userInfo.clientId != 11 && userInfo.clientId != 1}
                    onChange={(e) => {
                      textChange(e.target.id, e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </FormTab>
            <FormTab label="Shipment Details" sx={{ maxWidth: "60em" }}>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={3}>
                  <TextInput
                    source="shipperAccountNo"
                    label="Account No"
                    fullWidth
                    disabled
                    defaultValue={shipmentDto.accountNo}
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextInput
                    source="accountName"
                    label="Account Name"
                    fullWidth
                    disabled
                    defaultValue={shipmentDto.accountName}
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <TextInput
                    source="shipperAddress"
                    label="Address"
                    fullWidth
                    disabled
                    defaultValue={shipmentDto.accountAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextInput
                    source="shipperName"
                    label="Shipper Name c/o"
                    fullWidth
                    disabled
                    defaultValue={shipmentDto.shipperName}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextInput
                    source="attachment"
                    label="Attachment"
                    fullWidth
                    defaultValue=" "
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  {/* <DateInput source="pickupDate" label="Pickup Date" 
                        fullWidth validate={req} 
                        hidden ={true}
                        format={dateFormatter} parse={dateParser} defaultValue={getDefaultDate()}
                       />  */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Pickup Date"
                      inputFormat="MM/DD/YYYY"
                      validate={req}
                      value={pickupdateValue}
                      onChange={handleChangePickupDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          defaultValue={getDefaultDate()}
                          onChange={handleChangePickupDate}
                        />
                      )}
                      fullWidth
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextInput
                    source="originport"
                    label="Origin Port"
                    fullWidth
                    validate={req}
                    disabled
                    defaultValue={shipmentDto.originPort}
                  />
                </Grid>
                <Grid item xs={0} sm={12}></Grid>
                <Grid item xs={12} sm={3}>
                  <NumberInput
                    source="codamount"
                    label="COD Amount"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Php</InputAdornment>
                      ),
                    }}
                    fullWidth
                    defaultValue="0"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    source="referenceno"
                    label="Reference No"
                    defaultValue=" "
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextInput
                    source="specialinstructions"
                    label="Special Instructions/Landmark"
                    defaultValue=" "
                    fullWidth
                  />
                </Grid>
              </Grid>
            </FormTab>

            <FormTab label="Package Details" sx={{ maxWidth: "100%" }}>
              <Grid container columnSpacing={1}>
                <Grid item xs={12} sm={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={productCode}
                    renderInput={(params) => (
                      <TextField {...params} label="Product Type" />
                    )}
                    defaultValue={"Document"}
                    onChange={(e, value) => {
                      setProductCodeValue(value);
                      setPackingTypeValue("");
                      if (value != "Cargo" && value != "Non-Document") {
                        setIsDoc(false);
                      } else {
                        setIsDoc(true);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} hidden={isDoc}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={packingType.Document}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Package Type"
                        defaultValue={packingTypeValue}
                      />
                    )}
                    onChange={(e, value) => {
                      setPackingTypeValue(value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5} hidden={isDoc}>
                  <TextInput
                    source="packingdescription"
                    label="Descripton"
                    fullWidth
                    defaultValue={""}
                  />
                </Grid>
                <Grid item xs={12} sm={2} hidden>
                  <Button>
                    <AddIcon /> Add Cargo
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <NumberInput
                    label="Declared Value"
                    source="declaredvalue"
                    min={0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Php</InputAdornment>
                      ),
                    }}
                    // validate={req}
                    defaultValue="0"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3} hidden={isDoc}>
                  <NumberInput
                    source="quantity"
                    label="Quantity"
                    validate={req}
                    defaultValue="1"
                    min={1}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={3} hidden={isDoc}>
                  <NumberInput
                    source="weight"
                    min={0}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">Kg</InputAdornment>
                      ),
                    }}
                    defaultValue="0"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <p></p>
                </Grid>
                <Grid item xs={12} sm={2} hidden={!isDoc}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={
                      productCodeValue === "Cargo"
                        ? packingType.Cargo
                        : packingType.NonDocument
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Package Type" />
                    )}
                    onChange={(e, value) => {
                      setPackingTypeValue(value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} hidden={!isDoc}>
                  <TextInput
                    source="packingdescription"
                    label="Descripton"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5} sm={2} hidden={!isDoc}>
                  <NumberInput
                    source="length"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">cm</InputAdornment>
                      ),
                    }}
                    defaultValue="0"
                    fullWidth
                    min={0}
                  />
                </Grid>
                <Grid item xs={5} sm={2} hidden={!isDoc}>
                  <NumberInput
                    source="width"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">cm</InputAdornment>
                      ),
                    }}
                    defaultValue="0"
                    min={0}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5} sm={2} hidden={!isDoc}>
                  <NumberInput
                    source="height"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">cm</InputAdornment>
                      ),
                    }}
                    defaultValue="0"
                    min={0}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <div>
                <Confirm
                  isOpen={open}
                  title="Options"
                  content={<RadioButtonsGroup />}
                  onConfirm={handleConfirm}
                  onClose={handleDialogClose}
                />
              </div>
            </FormTab>
          </TabbedForm>
        </Create>
      </div>
    </div>
  );
};
export default BookingCreate;
