import React, { useState, useEffect } from 'react'
import {  
         Grid,
         Autocomplete,
         TextField,
         Button   
      } 
from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';

import {
   useNotify  ,
   useRedirect
} from "react-admin";
import Moment from 'moment';
import axios from "axios";
import { saveAs } from "file-saver";
import { apiUrl } from '../ApiUrl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs, { Dayjs } from 'dayjs';
const Reports = props =>
{
   const [userInfo, setUserInfo] = useState(() => {
      // getting stored value
      const saved = localStorage.getItem("auth");
      const initialValue = JSON.parse(saved);
      return initialValue || "";
    });

 

   const notify = useNotify();
   const clients = [
      { label:userInfo.accountName, Id:1} 
    ];
    const reportType = ['Transmittal ','For Upload'];
    const productCode = ['All','Document','Non-Document', 'Cargo'];
    const transationType = ['All','Valid', 'Cancelled'];

    
    const [reportTypeValue, setReportTypeValue] = useState(true);
    const [reportTypeDefaultValue, setReportTypeDefaultValue] = useState('Transmittal');
    const [productType, setProductType] = useState('All');
    const [transactionType, setTransactionType] = useState('Valid');
    const [checkboxDateCreated, setCheckboxDateCreated] = useState(true);
    const [checkboxPickupDate, setCheckboxPickupDate] = useState(true);
     
    function generateUUID() {
      var d = new Date().getTime();
      var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16;
          if(d > 0){
              var r = (d + r)%16 | 0;
              d = Math.floor(d/16);
          } else {
              var r = (d2 + r)%16 | 0;
              d2 = Math.floor(d2/16);
          }
          return (c==='x' ? r : (r&0x7|0x8)).toString(16);
      });
      return uuid;
  };
  const redirect = useRedirect();
    useEffect(() => { 

      if(userInfo.roleId === 0)
     {
        redirect('/');
     }
     
      if(userInfo.clientId ===5 || userInfo.clientId ===7 || userInfo.clientId ===9 || userInfo.clientId ===11 || userInfo.clientId ===13)
      {
         
         //setReportTypeDefaultValue('For Upload');
         setReportTypeValue(false);
      }
      else
      {
         //setReportTypeDefaultValue('Transmittal');
         setReportTypeValue(true);
      }
   } ,[]);

   const handleExtractClick = () =>{
      if(!checkboxDateCreated && !checkboxPickupDate)
      {
         notify('Please select the checkbox date created or pickup date.');
         return;
      }
      
      console.log('reportTypeDefaultValue:' + reportTypeDefaultValue);
      console.log('userInfo.clientId:' + userInfo.clientId);
      console.log('dateFromValue:' + dateFromValue);
      console.log('dateToValue:' + dateToValue);
      console.log('productType:' + productType);
      console.log('transactionType:' + transactionType);

      if(reportTypeDefaultValue === 'Transmittal')
      {
         axios({
            method: 'get',
            url:  apiUrl + "/Reports?reportType=" + reportTypeDefaultValue 
                                       + "&clientId=" + userInfo.clientId
                                       + "&username=" + userInfo.userName
                                       + "&dateFrom=" + dateFromValue
                                       + "&dateTo=" + dateToValue
                                       + "&productType=" + productType
                                       + "&transactionType=" + transactionType
                                       + "&pickupdateFrom=" + pickupDateValueFrom
                                       + "&pickupdateTo=" + pickupDateValueTo
                                       + "&isdatecreated=" + checkboxDateCreated
                                       + "&ispickupdate=" + checkboxPickupDate             
                                       ,
             responseType:"arraybuffer",                         
            
            })
            .then(function (response) {
                const blob = new Blob([response.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, generateUUID() + ".xlsx");
            })
            .catch(function (response) {
                //handle error
               //np alert(response);
               notify('No booking(s) found.');
                console.log(response);
            });
      }
      else
      {
            if(productType==="All")
            {
               
               let promises = [];

               for (var i = 1; i <=2; i++) 
               {
                  var p ="";

                  if(i === 1)
                  {
                        p="Document";
                  }
                  else
                  {
                     p="Cargo"; 

                  }
                  const response =   axios.get( apiUrl + "/Reports?reportType=" + reportTypeDefaultValue 
                                                + "&clientId=" + userInfo.clientId
                                                + "&username=" + userInfo.userName
                                                + "&dateFrom=" + dateFromValue
                                                + "&dateTo=" + dateToValue
                                                + "&productType=" + p
                                                + "&transactionType=" + transactionType
                                                + "&pickupdateFrom=" + pickupDateValueFrom
                                                + "&pickupdateTo=" + pickupDateValueTo
                                                + "&isdatecreated=" + checkboxDateCreated
                                                + "&ispickupdate=" + checkboxPickupDate ,{
                     responseType:"arraybuffer",  
                     })
                  .then((response) => {
                     const blob = new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                     });
                     saveAs(blob, generateUUID() + ".xlsx");
                  })
                  .catch(function (response) {
                    notify('No booking(s) found.');
                     console.log(response);
                 });

                 promises.push(response);
               }
             
            }
            else
            {
               axios({
                  method: 'get',
                  url:  apiUrl + "/Reports?reportType=" + reportTypeDefaultValue 
                                             + "&clientId=" + userInfo.clientId
                                             + "&username=" + userInfo.userName
                                             + "&dateFrom=" + dateFromValue
                                             + "&dateTo=" + dateToValue
                                             + "&productType=" + productType
                                             + "&transactionType=" + transactionType
                                             + "&pickupdateFrom=" + pickupDateValueFrom
                                             + "&pickupdateTo=" + pickupDateValueTo
                                             + "&isdatecreated=" + checkboxDateCreated
                                             + "&ispickupdate=" + checkboxPickupDate ,
                                             responseType:"arraybuffer",   
                  
                  })
                  .then(function (response) {
                      const blob = new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      });
                      saveAs(blob, generateUUID() + ".xlsx");
                  })
                  .catch(function (response) {
                     notify('No booking(s) found.');
                      console.log(response);
                  });

            }

      }

     
  }

const [dateFromValue, setDateFromValue] = React.useState(
     Moment().format('MM/DD/YYYY')
   //dayjs(Date.now())
 );
 const [dateToValue, setDateToValue] = React.useState(
   Moment().format('MM/DD/YYYY')
   //dayjs(Date.now())
 );
  const handleChangeDateFrom = ( value ) => {
   setDateFromValue(dayjs(value).format('MM/DD/YYYY'));
 };
 const handleChangeDateTo = ( value ) => {
   console.log(dayjs(value).format('MM/DD/YYYY'));
   setDateToValue(dayjs(value).format('MM/DD/YYYY'));
 };
 const [pickupDateValueFrom, setPickupDateValueFrom] = React.useState(
   Moment().format('MM/DD/YYYY')
  // dayjs(Date.now())
 );
 const [pickupDateValueTo, setPickupDateValueTo] = React.useState(
   Moment().format('MM/DD/YYYY')
  // dayjs(Date.now())
 );
 const handleChangePickupDateFrom = ( value ) => {
   setPickupDateValueFrom(dayjs(value).format('MM/DD/YYYY'));
 };
 const handleChangePickupDateTo = ( value ) => {
   setPickupDateValueTo(dayjs(value).format('MM/DD/YYYY'));
 };

   return( 
            <div style={{padding:20}}>
                  <Grid container  columnSpacing={1}>
                     <Grid item xs={12} sm={12}>
                     <Autocomplete
                                 disabled={reportTypeValue}
                                 id="comboReportType"
                                 options={reportType}
                                 sx={{width:200}}  
                                 renderInput={(params) => 
                                 <TextField {...params}  label="Report Type"   />}
                                 defaultValue={reportTypeDefaultValue}
                                 onChange={(event,value) => {
                                    setReportTypeDefaultValue(value);
                                 }}
                                 />
                     </Grid>
                     <Grid item xs={12} sm={12}>
                           <p></p>
                     </Grid>
                     <Grid item xs={12} sm={6}>
                           <Autocomplete
                                 disablePortal
                                 id="comboClients"
                                 options={clients}
                                 renderInput={(params) => 
                                 <TextField {...params}    label="Client"   />}
                                 value={userInfo.accountName}
                                 />
                     </Grid>
                     <Grid item xs={12} sm={6}></Grid>
                     <Grid item xs={12} sm={12}>
                           <p></p>
                     </Grid>
                     <Grid item xs={12} sm={2}>
                           <Checkbox  defaultChecked 
                              onChange={(e) => {
                                 setCheckboxDateCreated(e.target.checked);
                               }}
                           />
                           <label fullWidth>Date Created</label>
                     </Grid>
                     <Grid item xs={12} sm={3}>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                       label="From"
                                       inputFormat="MM/DD/YYYY"
                                      value={dateFromValue}
                                      onChange={handleChangeDateFrom}
                                       renderInput={(params) => <TextField {...params} fullWidth  value={dateFromValue} />}
                                       fullWidth
                                      />
                     </LocalizationProvider>
                     </Grid>
                     <Grid item xs={12} sm={3}>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                       label="To"
                                       inputFormat="MM/DD/YYYY"
                                       value={dateToValue}
                                       onChange={handleChangeDateTo}
                                       renderInput={(params) => <TextField {...params} fullWidth />}
                                       fullWidth
                                      />
                     </LocalizationProvider>
                               
                     </Grid>
                     <Grid item xs={12} sm={12}>
                           <p></p>
                     </Grid>
                     <Grid item xs={12} sm={2}>
                           <Checkbox  defaultChecked  
                           onChange={(e) => {
                              setCheckboxPickupDate(e.target.checked);
                            }}
                           />
                           <label fullWidth>Pickup Date </label>
                     </Grid>
                     <Grid item xs={12} sm={3}>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                                       <DesktopDatePicker
                                          label="From"
                                          inputFormat="MM/DD/YYYY"
                                          value={pickupDateValueFrom}
                                          onChange={handleChangePickupDateFrom}
                                          renderInput={(params) => <TextField {...params} fullWidth />}
                                          fullWidth
                                       />
                        </LocalizationProvider>
                             
                     </Grid>
                     <Grid item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                       <DesktopDatePicker
                                          label="To"
                                          inputFormat="MM/DD/YYYY"
                                          value={pickupDateValueTo}
                                          onChange={handleChangePickupDateTo}
                                          renderInput={(params) => <TextField {...params} fullWidth />}
                                          fullWidth
                                       />
                        </LocalizationProvider>
                     </Grid>
                     <Grid item xs={12} sm={12}><p> </p></Grid>
                     <Grid item xs={12} sm={4}>
                      
                           <Autocomplete
                                 disablePortal
                                 id="comboProduct"
                                 options={productCode}
                                 renderInput={(params) => 
                                 <TextField {...params}    label="Product Type"  
                                 onChange={(event,value) => {
                                    setProductType(value);
                                 }}
                                 />}
                                 defaultValue="All"
                                 onChange={(event,value) => {
                                    setProductType(value);
                                 }}
                                 />  
                     </Grid>
                     <Grid item xs={12} sm={4}>
                        <div>
                           
                           <Autocomplete
                                 disablePortal
                                 id="comboTransaction"
                                 options={transationType}
                                 renderInput={(params) => 
                                 <TextField {...params}    label="Transaction Status"   />}
                                 defaultValue="Valid"
                                 onChange={(event,value) => {
                                    setTransactionType(value);
                                 }}
                                 />
                        </div>
                              
                     </Grid>
                     <Grid item xs={12} sm={12}>
                     <br/>
                     <Divider/>
                     <br/>
                     <br/>
                     </Grid>   
                     <Grid item xs={12} sm={12}>
                     <Button variant="contained" color="primary"
                      onClick={() => {  handleExtractClick(); }}>
                        Extract
                     </Button>
                     </Grid>                         
                  </Grid>

            </div>
         );
};
export default Reports;